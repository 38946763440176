<template>
  <div class="record-picker">
    <blue-icon-button class="record-picker-title-button" title="调参记录"
      :icon="require('@imgs/pressure-parameter-record.png')"></blue-icon-button>
    <div class="record-picker-content">
      <div class="record-picker-content-item" :class="{
        'record-picker-content-item-selected': item.model.id == selectedID,
      }" v-for="(item, index) of dataArray" :key="index" @click="onPicked(item)">
        <a class="record-picker-content-item-title" :class="{
          'record-picker-content-item-title-selected':
            item.model.id == selectedID,
        }">{{ item.title }}</a>
      </div>
    </div>
    <p v-if="dataArray.length == 0" class="record-picker-empty">没有记录</p>
    <para-modify-record-alert v-if="isShowParaModifyRecordAlert" v-model="isShowParaModifyRecordAlert"
      :paraArray="paraArray"></para-modify-record-alert>
  </div>
</template>

<script>
import BlueIconButton from "@c/user-main/common/blue-icon-button.vue";
import { DateTool } from "@js/date-tool.js";

export default {
  components: {
    BlueIconButton,
    ParaModifyRecordAlert: () =>
      import("@c/common/alert/panel-alert/panel-alert-para-modify-record.vue"),
  },

  props: {
    uid: Number,
    hospitalId: Number,
  },

  data() {
    return {
      dataArray: [],
      selectedID: undefined,
      isShowParaModifyRecordAlert: false,
    };
  },

  created() {
    this.getModifyParaRecordList();
  },

  methods: {
    onPicked(item) {
      this.selectedID = item.model.id;
      const rawParaArray = item.model.message;
      if (!rawParaArray) {
        this.$toast.showRed("调参记录不存在");
      }
      this.paraArray = rawParaArray.map((item) => {
        return {
          title: placeholderText(item.name),
          oldValue: placeholderText(item.fromVal),
          newValue: placeholderText(item.targetVal),
        };
      });
      this.isShowParaModifyRecordAlert = true;
    },

    async getModifyParaRecordList() {
      try {
        const params = {
          hospitalId: this.hospitalId,
          uid: this.uid,
          pageNum: 1,
          pageSize: 100,
        };
        const data = await this.$api.getModifyParaRecordList(params);
        this.dataArray = data.list.map((item) => {
          return {
            title: DateTool.timestampToStr(item.createTime, "YYYY-MM-DD"),
            model: item,
          };
        });

      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.record-picker {
  border-left: 1px solid #e8e8e8;
  width: 242px;
  height: 210px;
  margin: 5px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-title-button {
    margin-top: 20px;
    margin-bottom: 5px;
    height: 33px;
  }

  &-content {
    width: 100%;
    overflow-y: scroll;

    &-item {
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &-selected {
        background-color: #e6f7ff;
      }

      &-title {
        color: black;
        font-size: 14px;
        font-weight: 300;

        &-selected {
          color: #2e6be6;
          border-bottom: 1px solid #2e6be6;
        }
      }
    }
  }

  &-empty {
    color: black;
    font-size: 14px;
    font-weight: 300;
  }
}
</style>