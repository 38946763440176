<!-- main->pap->提示窗中使用 -->
<template>
  <div class="key-value-unit">
    <p class="key-value-unit-key">{{ title }}：</p>
    <p class="key-value-unit-value">{{ value }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    value: String,
  },
};
</script>

<style lang="scss" scoped>
.key-value-unit {
  color: #666666;
  font-size: 14px;
  font-weight: 300;
  height: 40px;
  display: flex;
  align-items: center;

  &-key {
    height: 100%;
    min-width: 60px;
  }

  &-value {
    height: 100%;
  }
}
</style>
