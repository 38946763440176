<template>
  <div class="statistics">
    <pressure-section v-if="reportDuration" title="使用记录" buttonTitle="查看报告" @onButtonClick="onCheckReport">
      <template v-slot:sub-title>
        <div class="statistics-title">
          <p class="statistics-title-sub-title">{{ reportDurationStr }}</p>
          <duration-pick-bar class="statistics-title-duration-pick-bar" :inputSelectedTime="inputSelectedTime"
            @onDurationSelected="onDurationSelected"></duration-pick-bar>
        </div>
      </template>
    </pressure-section>
    <div class="statistics-content" v-if="statisticsReportModel">
      <statistics-charts :duration="reportDuration" :useTimeArray="statisticsReportModel.useTimeArray"
        :chartData="statisticsReportModel.chartData"></statistics-charts>
      <slot name="line-1"></slot>
      <statistics-data :census="statisticsReportModel.model.census"></statistics-data>
      <slot name="line-2"></slot>
      <single-charts class="statistics-content-single-chart" :reportArray="statisticsReportModel.reportArray">
      </single-charts>
    </div>
    <div v-else class="statistics-empty">
      <p>{{ errMsg }}</p>
    </div>
  </div>
</template>

<script>
import PressureSection from "@c/user-main/breath/breath-section/breath-section.vue";
import DurationPickBar from "@c/common/duration-pick-bar/duration-pick-bar.vue";
import StatisticsData from "@c/user-main/breath/breath-section/statistics-data/statistics-data.vue";
import StatisticsCharts from "@c/report/charts/user-main-statistics-charts.vue";
import SingleCharts from "@c/report/charts/user-main-single-charts.vue";
import { StatisticsReportModel } from "@js/model/statistics-report-model.js";
import { DateTool } from "@js/date-tool";
import { SelectedTimeModel } from "@c/common/duration-pick-bar/selected-time-model.js";

export default {
  components: {
    PressureSection,
    DurationPickBar,
    StatisticsCharts,
    StatisticsData,
    SingleCharts,
  },

  props: {
    uid: Number,
    hospitalId: Number,
  },

  data() {
    return {
      //selectedTime
      inputSelectedTime: new SelectedTimeModel("近3月", undefined),
      selectedTime: undefined,
      //data
      reportDuration: undefined,
      statisticsReportModel: undefined,
      errMsg: undefined,
    };
  },

  async created() {
    await this.getReportDuration(90);
    this.selectedTime = this.inputSelectedTime;
  },

  computed: {
    reportDurationStr() {
      const startDate = DateTool.timestampToStr(
        this.reportDuration[0],
        "YYYY.MM.DD"
      );
      const endDate = DateTool.timestampToStr(this.reportDuration[1], "YYYY.MM.DD");
      return `${startDate}-${endDate}`;
    },
  },

  methods: {
    onDurationSelected(selectedTime) {
      this.selectedTime = selectedTime;
      this.getReportDuration(selectedTime.getTime());
    },

    async getReportDuration(time) {
      try {
        let days, start, end
        if (isNaN(time)) {
          let duration = time.split("-")
          start = duration[0]
          end = duration[1]
        } else {
          days = time
        }
        const params = {
          hospitalId: this.hospitalId,
          uid: this.uid,
          time: days,
          start: start,
          end: end
        };
        const data = await this.$api.getReportDuration(params);
        this.reportDuration = [data.start, data.end];
        this.inputSelectedTime.duration = [data.start * 1000, data.end * 1000];
        this.getReportDetail();
      } catch {
        this.errMsg = "暂无报告";
        this.statisticsReportModel = undefined;
      }
    },

    async getReportDetail() {
      try {
        const params = {
          hospitalId: this.hospitalId,
          start: this.reportDuration[0],
          end: this.reportDuration[1],
          uid: this.uid,
        };
        const data = await this.$api.getReportStatisticsDetail(params);
        this.statisticsReportModel = new StatisticsReportModel(data);
      } catch (error) {
        this.errMsg = "暂无报告";
        this.statisticsReportModel = undefined;
      }
    },

    onCheckReport() {
      if (!this.statisticsReportModel) {
        this.$toast.showRed("暂无报告");
        return
      }
      let routeData = this.$router.resolve({
        path: "/report-frame",
        query: {
          uid: this.uid,
          statisticsDay: this.selectedTime.day,
          statisticsDuration: this.selectedTime.duration,
          hospitalId: this.hospitalId,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics {
  &-title {
    width: 1000px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-sub-title {
      color: black;
      font-size: 14px;
      margin-left: 51px;
    }

    &-duration-pick-bar {
      /deep/.duration-pick-bar-date-picker {
        margin-left: 10px;
      }
    }
  }

  &-content {
    &-single-chart {
      margin-bottom: 100px;
    }
  }

  &-empty {
    background-color: white;
    color: black;
    font-size: 28px;
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>