import { modeArray, breathPressureArray, pressureLevelArray, delayPressureArray, wetLevelArray, leakRemindTimeArray, maskTypeArray, tubeTypeArray, temperatureUnitTypeArray, commonArray, breathFrequencyArray, breathRatioArray, temperatureTypeArray } from "@js/parameter-option-data-source.js";
export class ParameterComposeTool {

    constructor(paramModel) {
        this.paramModel = paramModel
        this.cureModeStr = this._getCureModeStr()
    }

    _getCureModeStr() {
        return this.composeValue(modeArray, this.paramModel.cureMode)
    }

    // 患者详情-呼吸机治疗的展示参数
    async composeBreathParamArray(deviceDetail) {
        const asyncMaskTypeArray = await maskTypeArray()
        let dataArray = [
            [
                {
                    title: "型号",
                    info: placeholderText(deviceDetail?.model)
                },
                {
                    title: "序列号",
                    info: placeholderText(deviceDetail?.sn)
                },
                {
                    title: "工作模式",
                    info: placeholderText(this.cureModeStr)
                }
            ]
        ]
        const cureMode = parseInt(this.paramModel.cureMode)
        switch (cureMode) {
            case CURE_MODE.CPAP:
                dataArray.push(
                    [
                        {
                            title: "起始压力",
                            info: this.composePressure(this.paramModel.startPressure),
                        },
                        {
                            title: "治疗压力",
                            info: this.composePressure(this.paramModel.cpapP),
                        }
                    ]
                )
                break;
            case CURE_MODE.APAP:
                dataArray.push(
                    [
                        {
                            title: "起始压力",
                            info: this.composePressure(this.paramModel.startPressure),
                        },
                        {
                            title: "最大压力",
                            info: this.composePressure(this.paramModel.apapMaxP),
                        },
                        {
                            title: "最小压力",
                            info: this.composePressure(this.paramModel.apapMinP),
                        },
                    ]
                )
                break;
            case CURE_MODE.BPAPS:
                dataArray.push(
                    [
                        {
                            title: "起始压力",
                            info: this.composePressure(this.paramModel.startPressure),
                        },
                        {
                            title: "吸气压力",
                            info: this.composePressure(this.paramModel.bpapInP),
                        },
                        {
                            title: "呼气压力",
                            info: this.composePressure(this.paramModel.bpapExP),
                        }
                    ]
                )
                dataArray.push(
                    [
                        {
                            title: "升压速度",
                            info: this.composeValue(commonArray, this.paramModel.boostslope),
                        },
                        {
                            title: "降压速度",
                            info: this.composeValue(commonArray, this.paramModel.buckslope),
                        }, {
                            title: "吸气灵敏度",
                            info: this.composeValue(commonArray, this.paramModel.inhaleSensitive),
                        },
                        {
                            title: "呼气灵敏度",
                            info: this.composeValue(commonArray, this.paramModel.exhaleSensitive),
                        }
                    ]
                )
                break;
            case CURE_MODE.AutoBPAPS:

                dataArray.push(
                    [
                        {
                            title: "起始压力",
                            info: this.composePressure(this.paramModel.startPressure),
                        },
                        {
                            title: "最大吸气压力",
                            info: this.composePressure(this.paramModel.autosMaxP),
                        },
                        {
                            title: "最小呼气压力",
                            info: this.composePressure(this.paramModel.autosMinP),
                        },
                        {
                            title: "最大压力支持",
                            info: this.composePressure(this.paramModel.pressureSupport),
                        },
                        {
                            title: "最小压力支持",
                            info: this.composePressure(this.paramModel.autosMinPSupport),
                        }
                    ],
                )
                dataArray.push(
                    [
                        {
                            title: "升压速度",
                            info: this.composeValue(commonArray, this.paramModel.boostslope),
                        },
                        {
                            title: "降压速度",
                            info: this.composeValue(commonArray, this.paramModel.buckslope),
                        },
                        {
                            title: "吸气灵敏度",
                            info: this.composeValue(commonArray, this.paramModel.inhaleSensitive),
                        },
                        {
                            title: "呼气灵敏度",
                            info: this.composeValue(commonArray, this.paramModel.exhaleSensitive),
                        }
                    ]
                )
                break;
            case CURE_MODE.BPAPT:
                dataArray.push(
                    [
                        {
                            title: "起始压力",
                            info: this.composePressure(this.paramModel.startPressure),
                        },
                        {
                            title: "吸气压力",
                            info: this.composePressure(this.paramModel.tinP),
                        },
                        {
                            title: "呼气压力",
                            info: this.composePressure(this.paramModel.texP),
                        },
                    ]
                )
                dataArray.push(
                    [
                        {
                            title: "升压速度",
                            info: this.composeValue(commonArray, this.paramModel.boostslope),
                        },
                        {
                            title: "降压速度",
                            info: this.composeValue(commonArray, this.paramModel.buckslope),
                        },
                        {
                            title: "呼吸频率",
                            info: this.composeValue(breathFrequencyArray(), this.paramModel.breathRate),
                        },
                        {
                            title: "吸呼比",
                            info: this.composeValue(breathRatioArray(), this.paramModel.breathRatio),
                        }
                    ]
                )
                break;
            case CURE_MODE.BPAPST:
                dataArray.push(
                    [
                        {
                            title: "起始压力",
                            info: this.composePressure(this.paramModel.startPressure),
                        },
                        {
                            title: "吸气压力",
                            info: this.composePressure(this.paramModel.stInP),
                        },
                        {
                            title: "呼气压力",
                            info: this.composePressure(this.paramModel.stExP),
                        },
                    ]
                )
                dataArray.push(
                    [
                        {
                            title: "升压速度",
                            info: this.composeValue(commonArray, this.paramModel.boostslope),
                        },
                        {
                            title: "降压速度",
                            info: this.composeValue(commonArray, this.paramModel.buckslope),
                        },
                        {
                            title: "呼吸频率",
                            info: this.composeValue(breathFrequencyArray(), this.paramModel.breathRate),
                        },
                        {
                            title: "吸呼比",
                            info: this.composeValue(breathRatioArray(), this.paramModel.breathRatio),
                        }
                        , {
                            title: "最小吸气时间",
                            info: this.composeMinBreathInTime(),
                        }
                    ]
                )
                break;
            default:
                break;
        }
        switch (cureMode) {
            case CURE_MODE.CPAP:
            case CURE_MODE.APAP:
                dataArray.push(
                    [
                        {
                            title: "湿化等级",
                            info: this.composeValue(wetLevelArray, this.paramModel.humidifyLevel)
                        },
                        {
                            title: "管道类型",
                            info: this.composeHeatSetting()
                        },
                        {
                            title: "延迟升压",
                            info: this.composeValue(delayPressureArray(), this.paramModel.cureDelay)
                        },
                        {
                            title: "释压设置",
                            info: this.composePressureReleaseSetting()
                        },
                        {
                            title: "智能启动",
                            info: this.composeOpenClose(this.paramModel.intelligentStart),
                        },
                        {
                            title: "智能停止",
                            info: this.composeOpenClose(this.paramModel.intelligentStop),
                        },
                        {
                            title: "漏气提示时间",
                            info: this.composeValue(leakRemindTimeArray, this.paramModel.leakAlarm),
                        },
                        {
                            title: "面罩类型",
                            info: this.composeValue(asyncMaskTypeArray, this.paramModel.maskTypeId),
                        },
                    ]
                )
                break;
            default:
                dataArray.push(
                    [
                        {
                            title: "湿化等级",
                            info: this.composeValue(wetLevelArray, this.paramModel.humidifyLevel)
                        },
                        {
                            title: "延迟升压",
                            info: this.composeValue(delayPressureArray(), this.paramModel.cureDelay)
                        },
                        {
                            title: "智能启动",
                            info: this.composeOpenClose(this.paramModel.intelligentStart),
                        },
                        {
                            title: "智能停止",
                            info: this.composeOpenClose(this.paramModel.intelligentStop),
                        },
                        {
                            title: "漏气提示时间",
                            info: this.composeValue(leakRemindTimeArray, this.paramModel.leakAlarm),
                        },
                        {
                            title: "面罩类型",
                            info: this.composeValue(asyncMaskTypeArray, this.paramModel.maskTypeId),
                        },
                    ]
                )
                break;
        }
        return dataArray
    }

    // 患者记录-适应性评估/患者记录--压力滴定的展示参数
    // 全息视图-病史病例详情/全息视图--压力处方详情的展示参数
    async composeHolographyParamArray() {
        const asyncMaskTypeArray = await maskTypeArray()
        let dataArray = [{
            title: "工作模式",
            info: this.composeValue(modeArray, this.paramModel.cureMode)
        }]
        const cureMode = parseInt(this.paramModel.cureMode)
        switch (cureMode) {
            case CURE_MODE.CPAP:
                dataArray.push(...[
                    {
                        title: "起始压力",
                        info: this.composePressure(this.paramModel.startPressure),
                    },
                    {
                        title: "治疗压力",
                        info: this.composePressure(this.paramModel.cpapP),
                    },
                    {
                        title: "呼气释压",
                        info: this.composeValue(breathPressureArray, this.paramModel.depType),
                    },
                    {
                        title: "释压水平",
                        info: this.composeValue(pressureLevelArray, this.paramModel.depLevel),
                    }])
                break;
            case CURE_MODE.APAP:
                dataArray.push(...[
                    {
                        title: "起始压力",
                        info: this.composePressure(this.paramModel.startPressure),
                    },
                    {
                        title: "最大压力",
                        info: this.composePressure(this.paramModel.apapMaxP),
                    },
                    {
                        title: "最小压力",
                        info: this.composePressure(this.paramModel.apapMinP),
                    },
                    {
                        title: "呼气释压",
                        info: this.composeValue(breathPressureArray, this.paramModel.depType),
                    },
                    {
                        title: "释压水平",
                        info: this.composeValue(pressureLevelArray, this.paramModel.depLevel),
                    }]
                )
                break;
            case CURE_MODE.BPAPS:
                dataArray.push(...[
                    {
                        title: "起始压力",
                        info: this.composePressure(this.paramModel.startPressure),
                    },
                    {
                        title: "吸气压力",
                        info: this.composePressure(this.paramModel.bpapInP),
                    },
                    {
                        title: "呼气压力",
                        info: this.composePressure(this.paramModel.bpapExP),
                    },
                    {
                        title: "升压速度",
                        info: this.composeValue(commonArray, this.paramModel.boostslope),
                    },
                    {
                        title: "降压速度",
                        info: this.composeValue(commonArray, this.paramModel.buckslope),
                    }, {
                        title: "吸气灵敏度",
                        info: this.composeValue(commonArray, this.paramModel.inhaleSensitive),
                    },
                    {
                        title: "呼气灵敏度",
                        info: this.composeValue(commonArray, this.paramModel.exhaleSensitive),
                    }])
                break;
            case CURE_MODE.AutoBPAPS:
                dataArray.push(...[
                    {
                        title: "起始压力",
                        info: this.composePressure(this.paramModel.startPressure),
                    },
                    {
                        title: "最大压力支持",
                        info: this.composePressure(this.paramModel.pressureSupport),
                    },
                    {
                        title: "最小压力支持",
                        info: this.composePressure(this.paramModel.autosMinPSupport),
                    },
                    {
                        title: "最大吸气压力",
                        info: this.composePressure(this.paramModel.autosMaxP),
                    },
                    {
                        title: "最小呼气压力",
                        info: this.composePressure(this.paramModel.autosMinP),
                    },
                    {
                        title: "升压速度",
                        info: this.composeValue(commonArray, this.paramModel.boostslope),
                    },
                    {
                        title: "降压速度",
                        info: this.composeValue(commonArray, this.paramModel.buckslope),
                    },
                    {
                        title: "吸气灵敏度",
                        info: this.composeValue(commonArray, this.paramModel.inhaleSensitive),
                    },
                    {
                        title: "呼气灵敏度",
                        info: this.composeValue(commonArray, this.paramModel.exhaleSensitive),
                    }])
                break;
            case CURE_MODE.BPAPT:
                dataArray.push(...[
                    {
                        title: "起始压力",
                        info: this.composePressure(this.paramModel.startPressure),
                    },
                    {
                        title: "吸气压力",
                        info: this.composePressure(this.paramModel.tinP),
                    },
                    {
                        title: "呼气压力",
                        info: this.composePressure(this.paramModel.texP),
                    },
                    {
                        title: "升压速度",
                        info: this.composeValue(commonArray, this.paramModel.boostslope),
                    },
                    {
                        title: "降压速度",
                        info: this.composeValue(commonArray, this.paramModel.buckslope),
                    },
                    {
                        title: "呼吸频率",
                        info: this.composeValue(breathFrequencyArray(), this.paramModel.breathRate),
                    },
                    {
                        title: "吸呼比",
                        info: this.composeValue(breathRatioArray(), this.paramModel.breathRatio),
                    }])
                break;
            case CURE_MODE.BPAPST:
                dataArray.push(...[
                    {
                        title: "起始压力",
                        info: this.composePressure(this.paramModel.startPressure),
                    },
                    {
                        title: "吸气压力",
                        info: this.composePressure(this.paramModel.stInP),
                    },
                    {
                        title: "呼气压力",
                        info: this.composePressure(this.paramModel.stExP),
                    },
                    {
                        title: "升压速度",
                        info: this.composeValue(commonArray, this.paramModel.boostslope),
                    },
                    {
                        title: "降压速度",
                        info: this.composeValue(commonArray, this.paramModel.buckslope),
                    },
                    {
                        title: "呼吸频率",
                        info: this.composeValue(breathFrequencyArray(), this.paramModel.breathRate),
                    },
                    {
                        title: "吸呼比",
                        info: this.composeValue(breathRatioArray(), this.paramModel.breathRatio),
                    }
                    , {
                        title: "最小吸气时间",
                        info: this.composeMinBreathInTime(),
                    }])
                break;
            default:
                break;
        }
        dataArray.push(...[{
            title: "延迟升压",
            info: this.composeValue(delayPressureArray(), this.paramModel.cureDelay)
        },
        {
            title: "湿化等级",
            info: this.composeValue(wetLevelArray, this.paramModel.humidifyLevel)
        },
        {
            title: "智能启动",
            info: this.composeOpenClose(this.paramModel.intelligentStart),
        },
        {
            title: "智能停止",
            info: this.composeOpenClose(this.paramModel.intelligentStop),
        },
        {
            title: "漏气提示时间",
            info: this.composeValue(leakRemindTimeArray, this.paramModel.leakAlarm),
        },
        {
            title: "面罩类型",
            info: this.composeValue(asyncMaskTypeArray, this.paramModel.maskTypeId),
        },
        {
            title: "管道类型",
            info: this.composeValue(tubeTypeArray, this.paramModel.pipe),
        },
        {
            title: "加温温度",
            info: this.composeTemperature(),
        }
        ])
        return dataArray
    }


    // 全息视图-压力处方的展示参数
    composeHolographyListParamArray() {
        let dataArray = [{
            title: "工作模式",
            info: this.composeValue(modeArray, this.paramModel.cureMode)
        }]
        const cureMode = parseInt(this.paramModel.cureMode)

        switch (cureMode) {
            case CURE_MODE.CPAP:
                dataArray.push(...[
                    {
                        title: "起始压力",
                        info: this.composePressure(this.paramModel.startPressure),
                    },
                    {
                        title: "治疗压力",
                        info: this.composePressure(this.paramModel.cpapP),
                    },
                    {
                        title: "呼气释压",
                        info: this.composeBreathPressure()
                    }])
                break;
            case CURE_MODE.APAP:
                dataArray.push(...[
                    {
                        title: "起始压力",
                        info: this.composePressure(this.paramModel.startPressure),
                    },
                    {
                        title: "最大压力",
                        info: this.composePressure(this.paramModel.apapMaxP),
                    },
                    {
                        title: "最小压力",
                        info: this.composePressure(this.paramModel.apapMinP),
                    },
                    {
                        title: "呼气释压",
                        info: this.composeBreathPressure()
                    }]
                )
                break;
            case CURE_MODE.BPAPS:
                dataArray.push(...[
                    {
                        title: "起始压力",
                        info: this.composePressure(this.paramModel.startPressure),
                    },
                    {
                        title: "吸气压力",
                        info: this.composePressure(this.paramModel.bpapInP),
                    },
                    {
                        title: "呼气压力",
                        info: this.composePressure(this.paramModel.bpapExP),
                    }])
                break;
            case CURE_MODE.AutoBPAPS:
                dataArray.push(...[
                    {
                        title: "起始压力",
                        info: this.composePressure(this.paramModel.startPressure),
                    },
                    {
                        title: "最大吸气压力",
                        info: this.composePressure(this.paramModel.autosMaxP),
                    },
                    {
                        title: "最小呼气压力",
                        info: this.composePressure(this.paramModel.autosMinP),
                    }])
                break;
            case CURE_MODE.BPAPT:
                dataArray.push(...[
                    {
                        title: "起始压力",
                        info: this.composePressure(this.paramModel.startPressure),
                    },
                    {
                        title: "吸气压力",
                        info: this.composePressure(this.paramModel.tinP),
                    },
                    {
                        title: "呼气压力",
                        info: this.composePressure(this.paramModel.texP),
                    }])
                break;
            case CURE_MODE.BPAPST:
                dataArray.push(...[
                    {
                        title: "起始压力",
                        info: this.composePressure(this.paramModel.startPressure),
                    },
                    {
                        title: "吸气压力",
                        info: this.composePressure(this.paramModel.stInP),
                    },
                    {
                        title: "呼气压力",
                        info: this.composePressure(this.paramModel.stExP),
                    }])
                break;
            default:
                break;
        }
        dataArray.push(...[{
            title: "延迟升压",
            info: this.composeValue(delayPressureArray(), this.paramModel.cureDelay)
        }
        ])
        return dataArray
    }

    composeValue(array, str) {
        let value = null
        const rawValue = parseInt(str)
        if (!isNaN(rawValue)) {
            for (const item of array) {
                if (rawValue == item.value) {
                    value = item.title
                    break
                }
            }
        }
        return placeholderText(value)
    }

    composePressure(str) {
        let value = null
        const rawValue = parseFloat(str)
        if (!isNaN(rawValue)) {
            value = (rawValue / 10).toFixed(1)
        }
        return `${value}cmH2O`
    }

    composeOpenClose(str) {
        let value = null
        const rawValue = parseFloat(str)
        switch (rawValue) {
            case 1:
                value = "开启"
                break;
            case 0:
                value = "关闭"
                break;
            default:
                break;
        }
        return placeholderText(value)
    }

    composeMinBreathInTime() {
        let value = null
        const rawValue = parseFloat(this.paramModel.stTimin)
        if (!isNaN(rawValue)) {
            value = (rawValue / 10).toFixed(1)
        }
        return placeholderText(value, "s")
    }

    composeTemperature() {
        let temperature = null
        const rawTemprature = parseInt(this.paramModel.heattubeTem)
        if (!isNaN(rawTemprature)) {
            temperature = rawTemprature == 0 ? null : `${rawTemprature}`
        }
        // let unit = this.composeValue(temperatureUnitTypeArray, this.paramModel.temperatureUnit)
        let unit = '°C'
        return placeholderText(temperature, unit)
    }

    composeHeatSetting() {
        const pipSizeStr = this.composeValue(temperatureTypeArray, this.paramModel.pipe)
        if (pipSizeStr == '15mm') {
            const temperature = this.paramModel.heattubeTem
            return `加温管路${temperature}°C`
        } else {
            return '普通管路'
        }
    }

    composePressureReleaseSetting() {
        const pressureReleaseStr = this.composeValue(breathPressureArray, this.paramModel.depType)
        if (pressureReleaseStr == '关闭') {
            return pressureReleaseStr
        } else {
            const pressureReleaseLevel = this.composeValue(pressureLevelArray, this.paramModel.depLevel)
            return `${pressureReleaseStr}${pressureReleaseLevel}cmH2O`
        }
    }

    composeBreathPressure() {
        if (this.paramModel.depType == 0) { //关闭
            return `${this.composeValue(breathPressureArray, this.paramModel.depType)}`
        } else {
            return `${this.composeValue(breathPressureArray, this.paramModel.depType)} ${this.composeValue(pressureLevelArray, this.paramModel.depLevel)}`
        }
    }

}