<template>
  <div class="statistics-chart">
    <use-time-chart class="statistics-chart-time-chart" :style="`height: ${290 / zoom}px;`" :useTimeArray="useTimeArray"
      :isShowTitle="false"></use-time-chart>
    <statistics-chart class="statistics-chart-ahi-chart" title="AHI" :max="chartData.maxAHI" :dayArray="dayArray"
      :chartDataArray="ahiDataArray"></statistics-chart>
    <statistics-chart class="statistics-chart-use-time-chart" title="使用时长（小时）" :max="24" :dayArray="dayArray"
      :chartDataArray="useHourArray"></statistics-chart>
    <statistics-chart class="statistics-chart-leakage-chart" title="漏气（L/min)" :max="chartData.maxLeakage"
      :dayArray="dayArray" :chartDataArray="leakageArray"></statistics-chart>
  </div>
</template>

<script>
import UseTimeChart from "@c/report/charts/chart/statistics-chart/use-time-chart/use-time-chart.vue";
import StatisticsChart from "@c/report/charts/chart/statistics-chart/statistics-chart.vue";
import { DateTool } from "@js/date-tool.js";
export default {
  components: {
    UseTimeChart,
    StatisticsChart,
  },

  props: {
    duration: Array,
    useTimeArray: Array,
    chartData: Object,
  },

  computed: {
    zoom() {
      return this.$store.state.zoom
    },

    durationStr() {
      const startDate = placeholderText(
        DateTool.timestampToStr(this.duration[0], "YYYY.MM.DD")
      );
      const endDate = placeholderText(
        DateTool.timestampToStr(this.duration[1], "YYYY.MM.DD")
      );
      return `${startDate}-${endDate}`;
    },

    dayArray() {
      return this.chartData.dayArray;
    },

    ahiDataArray() {
      return [
        {
          name: "AI",
          type: "bar",
          color: "#5AC6FD",
          stack: "ahi",
          dataArray: this.chartData.aiArray,
        },
        {
          name: "HI",
          type: "bar",
          color: "#A64DFB",
          stack: "ahi",
          dataArray: this.chartData.hiArray,
        },
      ];
    },

    useHourArray() {
      return [
        {
          name: "大于4小时",
          type: "bar",
          color: "#58C93D",
          stack: null,
          dataArray: this.chartData.useHourAbove4Array,
        },
        {
          name: "小于4小时",
          type: "bar",
          color: "#FF4E4E",
          stack: null,
          dataArray: this.chartData.useHourUnder4Array,
        },
      ];
    },

    leakageArray() {
      return [
        {
          name: "大于5（L/min)",
          type: "bar",
          color: "#D9732F",
          stack: null,
          dataArray: this.chartData.leakageAbove5Array,
        },
        {
          name: "小于5（L/min)",
          type: "bar",
          color: "#F9CD14",
          stack: null,
          dataArray: this.chartData.leakageUnder5Array,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics-chart {
  padding-top: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &-time-chart {
    width: 49.9%;
    height: 275px;
    overflow-y: scroll;
  }

  &-ahi-chart {
    width: 49.9%;
    margin-bottom: 0px;
    box-sizing: border-box;
    padding-top: 20px;
  }

  &-use-time-chart {
    margin-top: 4px;
    width: 49.9%;
    margin-bottom: 0px;
    box-sizing: border-box;
    padding-top: 20px;
  }

  &-leakage-chart {
    margin-top: 4px;
    width: 49.9%;
    margin-bottom: 0px;
    box-sizing: border-box;
    padding-top: 20px;
  }
}
</style>