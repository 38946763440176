<template>
  <pressure-section class="pressure-chart" title="详细图形">
    <template v-slot:content>
      <div class="pressure-chart-content">
        <div class="pressure-chart-content-bar">
          <chart-selector class="pressure-chart-content-bar-chart-selector"
            :chartsDisplayInfoArray="chartsDisplayInfoArray" @onSelectedCharts="onSelectedCharts" />
          <list-picker class="pressure-chart-content-bar-list-picker"
            :arrowIcon="require('@imgs/report-arrow-down.png')" :inputInfoArray="reportDateArray"
            :inputSelectedInfo="reportDateArray[inputSelectedIndex]" @onSelectedIndexChanged="onReportPicked" />
        </div>
        <single-charts v-if="singleReportModel" :chartWidth="1586" :timeSelectBarWidth="1460"
          :reportModel="singleReportModel" :chartsDisplayInfoArray="chartsDisplayInfoArray"
          :printHeaderInfo="printHeaderInfo">
        </single-charts>
      </div>
    </template>
  </pressure-section>
</template>

<script>
import PressureSection from "@c/user-main/breath/breath-section/breath-section.vue";
import ChartSelector from "@c/report/chart-selector.vue";
import ListPicker from "@c/common/list-picker/list-picker.vue";
import SingleCharts from "@c/report/charts/single-charts.vue";
import { ReportModel } from "@js/model/report-model.js";
export default {
  components: {
    PressureSection,
    ChartSelector,
    ListPicker,
    SingleCharts
  },

  props: {
    reportArray: Array,
  },

  data() {
    return {
      inputSelectedIndex: 0,
      singleReportModel: undefined,
      printHeaderInfo: undefined,
      chartsDisplayInfoArray: [
        {
          name: "压力",
          isChecked: true,
          needBreak: [],
        },
        {
          name: "流量",
          isChecked: true,
          needBreak: [3, 4],
        },
        {
          name: "事件",
          isChecked: true,
          needBreak: [],
        },
        {
          name: "漏气",
          isChecked: false,
          needBreak: [],
        },
        {
          name: "潮气量",
          isChecked: false,
          needBreak: [],
        },
        {
          name: "呼吸率",
          isChecked: false,
          needBreak: [],
        },
        {
          name: "分钟通气量",
          isChecked: false,
          needBreak: [],
        },
        {
          name: "血氧",
          isChecked: false,
          needBreak: [],
        },
        {
          name: "心率",
          isChecked: false,
          needBreak: [],
        },
      ],
    };
  },

  computed: {
    reportDateArray() {
      return this.reportArray.map((item) => {
        return item.createDate;
      });
    },
  },

  created() {
    this.getSingleReportDetail();
  },

  watch: {
    reportArray() {
      this.inputSelectedIndex = 0
      this.getSingleReportDetail();
    },
  },

  methods: {
    onReportPicked(index) {
      this.inputSelectedIndex = index;
      this.getSingleReportDetail();
    },

    onSelectedCharts(chartsDisplayInfoArray) {
      this.chartsDisplayInfoArray = chartsDisplayInfoArray;
    },

    async getSingleReportDetail() {
      try {
        const params = {
          id: this.reportArray[this.inputSelectedIndex].id,
        };
        const data = await this.$api.getSingleReportDetail(params);
        this.singleReportModel = new ReportModel(data);
        this.printHeaderInfo = {
          patientName: this.singleReportModel.model.user.name,
          deviceModel: this.singleReportModel.deviceModel,
          deviceSN: this.singleReportModel.deviceSN,
          statisticsTime: this.singleReportModel.deviceStatisticsDuration,
        };
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pressure-chart {
  &-content {
    display: flex;
    flex-direction: column;

    &-bar {
      margin-top: -10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-list-picker {
        width: 200px;
        height: 32px;
      }

      &-chart-selector {
        margin-top: 0px;
      }
    }
  }
}
</style>