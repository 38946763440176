<template>
  <div class="statistics-data-box">
    <p class="statistics-data-box-title">{{ title }}</p>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.statistics-data-box {
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  width: 49.9%;
  box-sizing: border-box;
  padding: 17px 108px 20px 26px;
  margin-bottom: 4px;

  &-title {
    color: black;
    font-size: 14px;
    height: 19px;
    margin-bottom: 10px;
  }
}
</style>