<template>
  <pressure-section class="statistics-data" title="使用统计">
    <template v-slot:content>
      <div class="statistics-data-content">
        <statistics-data-box title="治疗压力">
          <template v-slot:content>
            <div class="statistics-data-content-row">
              <statistics-data-unit title="90%吸气压力" :value="pressure90BreatheIn"></statistics-data-unit>
              <statistics-data-unit v-show="双水平模式" title="90%呼气压力" :value="pressure90BreatheOut"></statistics-data-unit>
            </div>
          </template>
        </statistics-data-box>
        <statistics-data-box title="漏气">
          <template v-slot:content>
            <div class="statistics-data-content-row">
              <statistics-data-unit title="平均漏气" :value="averageLeakage"></statistics-data-unit>
              <statistics-data-unit title=" 总漏气" :value="totalLeakage"></statistics-data-unit>
            </div>
          </template>
        </statistics-data-box>
        <statistics-data-box title="血氧">
          <template v-slot:content>
            <div class="statistics-data-content-row">
              <statistics-data-unit title="平均血氧" :value="averageBloodOxygen"></statistics-data-unit>
              <statistics-data-unit title="最小血氧" :value="minBloodOxygen"></statistics-data-unit>
            </div>
            <div class="statistics-data-content-row">
              <statistics-data-unit title="<90血氧" :value="below90BloodOxygenTime"></statistics-data-unit>
              <statistics-data-unit title="总时长" :value="totalTime"></statistics-data-unit>
            </div>
          </template>
        </statistics-data-box>
        <statistics-data-box title="心率">
          <template v-slot:content>
            <div class="statistics-data-content-row">
              <statistics-data-unit title="平均心率" :value="averagePulse"></statistics-data-unit>
              <statistics-data-unit title="最小心率" :value="minPulse"></statistics-data-unit>
            </div>
            <div class="statistics-data-content-row">
              <statistics-data-unit title="最大心率" :value="maxPulse"></statistics-data-unit>
            </div>
          </template>
        </statistics-data-box>
        <statistics-data-box title="使用信息">
          <template v-slot:content>
            <div class="statistics-data-content-row">
              <statistics-data-unit title="总天数" :value="totalUseDayCount"></statistics-data-unit>
              <statistics-data-unit title="≥4小时天数" :value="above4DayCount"></statistics-data-unit>
            </div>
            <div class="statistics-data-content-single-row">
              <statistics-data-unit title="＜4小时天数" :value="below4DayCount"></statistics-data-unit>
            </div>
            <div class="statistics-data-content-single-row">
              <statistics-data-unit title="未使用天数" :value="unUseDayCount"></statistics-data-unit>
            </div>
            <div class="statistics-data-content-row">
              <statistics-data-unit title="总时长" :value="totalUseTime"></statistics-data-unit>
              <statistics-data-unit title="平均时长" :value="averageUseTime"></statistics-data-unit>
            </div>
          </template>
        </statistics-data-box>
        <statistics-data-box title="事件统计">
          <template v-slot:content>
            <div class="statistics-data-content-row">
              <statistics-data-unit title="AHI" :value="ahi"></statistics-data-unit>
              <statistics-data-unit title="AI" :value="ai"></statistics-data-unit>
            </div>
            <div class="statistics-data-content-single-row">
              <statistics-data-unit title="HI" :value="hi"></statistics-data-unit>
            </div>
            <div class="statistics-data-content-single-row">
              <statistics-data-unit title="CSR" :value="csr"></statistics-data-unit>
            </div>
            <div class="statistics-data-content-row">
              <statistics-data-unit title="CSA" :value="csa"></statistics-data-unit>
              <statistics-data-unit title="Biot" :value="biot"></statistics-data-unit>
            </div>
          </template>
        </statistics-data-box>
      </div>
    </template>
  </pressure-section>
</template>

<script>
import PressureSection from "@c/user-main/breath/breath-section/breath-section.vue";
import StatisticsDataBox from "@c/user-main/breath/breath-section/statistics-data/statistics-data-box.vue";
import StatisticsDataUnit from "@c/common/key-value-unit/key-value-unit-user-main-pressure.vue";
import { DateTool } from "@js/date-tool.js";
export default {
  components: {
    PressureSection,
    StatisticsDataBox,
    StatisticsDataUnit,
  },

  props: {
    census: Object,
  },

  computed: {
    pressure90BreatheIn() {
      return placeholderText(this.census.tpIn, "cmH2O");
    },

    //双水平模式还要显示呼气压力
    双水平模式() {
      const cureMode = this.census.cureModel
      return !(cureMode == 'APAP' || cureMode == 'CPAP' || cureMode == 'CPAP,APAP' || cureMode == 'APAP,CPAP') //非单水平
    },

    pressure90BreatheOut() {
      return placeholderText(this.census.tpEx, "cmH2O");
    },

    averageLeakage() {
      return placeholderText(this.census.leakage, "L/min");
    },

    totalLeakage() {
      return placeholderText(this.census.totalLeakage, "L");
    },

    averageBloodOxygen() {
      const value = parseFloat(this.census.spoAvg);
      return placeholderText(value, "%");
    },

    minBloodOxygen() {
      const value = parseFloat(this.census.spoMin);
      return placeholderText(value, "%");
    },

    below90BloodOxygenTime() {
      return placeholderText(DateTool.formatSeconds(this.census.spoLowTime));
    },

    totalTime() {
      return placeholderText(DateTool.formatSeconds(this.census.spoTime));
    },

    averagePulse() {
      const value = parseFloat(this.census.bpmAvg);
      return placeholderText(value, "bpm");
    },

    minPulse() {
      const value = parseFloat(this.census.bpmMin);
      return placeholderText(value, "bpm");
    },

    maxPulse() {
      const value = parseFloat(this.census.bpmMax);
      return placeholderText(value, "bpm");
    },

    totalUseDayCount() {
      return placeholderText(`${this.census.dayTotal}`);
    },

    above4DayCount() {
      const dayCount = `${this.census.longTimeAft}`;
      const percent = `${Math.round(
        (this.census.longTimeAft / this.census.dayTotal) * 100
      )}`;
      return `${dayCount}（${placeholderText(percent)}%）`;
    },

    below4DayCount() {
      const dayCount = `${this.census.longTimePre}`;
      const percent = `${Math.round(
        (this.census.longTimePre / this.census.dayTotal) * 100
      )}`;
      return `${dayCount}（${placeholderText(percent)}%）`;
    },

    unUseDayCount() {
      const dayCount = `${this.census.dayTotal - this.census.dayUse}`;
      const percent = `${Math.round(
        ((this.census.dayTotal - this.census.dayUse) / this.census.dayTotal) *
        100
      )}`;
      return `${dayCount}（${placeholderText(percent)}%）`;
    },

    totalUseTime() {
      return placeholderText(DateTool.formatSeconds(this.census.longTime));
    },

    averageUseTime() {
      return placeholderText(DateTool.formatSeconds(this.census.longTimeAvg));
    },

    ahi() {
      return placeholderText(this.census.ahi);
    },

    ai() {
      return placeholderText(this.census.ai);
    },

    hi() {
      return placeholderText(this.census.hi);
    },

    csr() {
      return placeholderText(this.census.csr);
    },

    csa() {
      return placeholderText(this.census.csa);
    },

    biot() {
      return placeholderText(this.census.pb);
    },
  },
};
</script>
    StatisticsBox

<style lang="scss" scoped>
.statistics-data {
  &-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-single-row {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
</style>