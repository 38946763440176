<template>
  <div>
    <pressure-section class="pressure-parameter" title="设备参数" :buttonTitle="buttonTitle" @onButtonClick="onButtonClick">
      <template v-slot:content>
        <div class="pressure-parameter-content">
          <parameter-row v-for="(item, index) of paramArray" :key="index" :dataArray="item"></parameter-row>
        </div>
      </template>
    </pressure-section>
    <bind-alert v-if="isShowBindAlert" :initSelectedPatient="patientDetailModel"
      @onCloseClick="onBindAlertCloseClick" @onBindSucceed="onBindSucceed"></bind-alert>
    <unbind-alert v-if="isShowUnbindAlert" :patientDetailModel="patientDetailModel"
      @onCloseClick="onUnbindAlertCloseClick" @onUnbindSucceedClick="onUnbindSucceed"></unbind-alert>
  </div>
</template>

<script>
  import PressureSection from "@c/user-main/breath/breath-section/breath-section.vue";
  import BlueIconButton from "@c/user-main/common/blue-icon-button.vue";
  import ParameterRow from "@c/user-main/breath/breath-section/parameter/parameter-row.vue";
  import { ParameterComposeTool } from "@js/parameter-compose-tool.js";
  import EventBus from "@js/event-bus.js";
  export default {
    components: {
      PressureSection,
      BlueIconButton,
      ParameterRow,
      UnbindAlert: () =>
        import(
          "@c/common/alert/panel-alert/panel-alert-unbind-breath-machine.vue"
        ),
      BindAlert: () =>
        import("@c/common/alert/panel-alert/panel-alert-bind-breath-machine.vue"),
    },

    props: {
      patientDetailModel: Object,
    },

    data() {
      return {
        deviceID: undefined,
        deviceDetail: undefined,
        paramArray: [],
        parameterComposeTool: undefined,
        isShowUnbindAlert: false,
        isShowBindAlert: false,
      };
    },

    created() {
      this.smartGetDeviceDetail();
    },

    computed: {
      buttonTitle() {
        return this.deviceID ? "设备解绑" : "设备绑定";
      }

    },

    methods: {
      onButtonClick() {
        switch (this.buttonTitle) {
          case "设备解绑":
            this.isShowUnbindAlert = true;
            break;
          case "设备绑定":
            this.isShowBindAlert = true;
            break;
          default:
            break;
        }
      },

      //绑定面板
      onBindAlertCloseClick() {
        this.isShowBindAlert = false;
      },

      onBindSucceed(bindInfo) {
        this.$toast.showGreen("设备绑定成功");
        this.isShowBindAlert = false;
        this.deviceID = bindInfo.deviceID;
        this.getDeviceDetail();
        this.getDeviceParams();
        EventBus.$emit("onPatientInfoChanged")
        this.$emit("onParaAdjustSucceed")
      },

      //解绑面板
      onUnbindAlertCloseClick() {
        this.isShowUnbindAlert = false;
      },

      onUnbindSucceed() {
        this.$toast.showGreen("设备解绑成功");
        EventBus.$emit("onPatientInfoChanged")
        this.isShowUnbindAlert = false;
        this.deviceID = undefined;
        this.deviceDetail = undefined;
        this.paramArray = [
          [
            {
              title: "型号",
              info: placeholderText(null)
            },
            {
              title: "序列号",
              info: placeholderText(null)
            },
            {
              title: "工作模式",
              info: placeholderText(null)
            }
          ]
        ]
      },

      async smartGetDeviceDetail() {
          this.deviceID = this.patientDetailModel.model.deviceId
          if (this.deviceID) {
            this.getDeviceDetail();
          } else {
            this.paramArray = [
              [
                {
                  title: "型号",
                  info: placeholderText(null)
                },
                {
                  title: "序列号",
                  info: placeholderText(null)
                },
                {
                  title: "工作模式",
                  info: placeholderText(null)
                }
              ]
            ]
          }
      },

      async getDeviceDetail() {
        try {
          const params = {
            uid: this.patientDetailModel.model.id
          }
          const data = await this.$api.getDeviceDetail(params)
          this.deviceDetail = data;
          this.getDeviceParams();
        } catch (error) {
          this.$toast.showRed(error);
        }
      },

      async getDeviceParams() {
        try {
          const params = {
            deviceId: this.deviceID
          }
          const paramModel = await this.$api.getDeviceParams(params)
          this.parameterComposeTool = new ParameterComposeTool(paramModel)
          this.paramArray = await this.parameterComposeTool.composeBreathParamArray(this.deviceDetail)
        } catch (error) {
          this.$toast.showRed(error)
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .pressure-parameter {
    &-justify {
      margin-left: 51px;
    }

    &-content {
      display: flex;
      flex-direction: column;
    }
  }
</style>