<template>
  <div v-if="patientDetailModel" class="report">
    <div class="report-container">
      <div class="report-container-top">
        <parameter class="report-container-top-parameter" :patientDetailModel="patientDetailModel"
          @onParaAdjustSucceed="onParaAdjustSucceed">
        </parameter>
        <record-picker ref="recordPicker" :uid="patientDetailModel.model.id"
          :hospitalId="patientDetailModel.model.hospitalId"></record-picker>
      </div>
      <div class="report-container-line"></div>
      <statistics :uid="patientDetailModel.model.id" :hospitalId="patientDetailModel.model.hospitalId">
        <template v-slot:line-1>
          <div class="report-container-line" style="margin-top: 40px"></div>
        </template>
        <template v-slot:line-2>
          <div class="report-container-line" style="margin-top: 35px; margin-bottom: 10px"></div>
        </template>
      </statistics>
    </div>
  </div>
</template>

<script>
import Parameter from "@c/user-main/breath/breath-section/parameter/parameter.vue";
import RecordPicker from "@c/user-main/breath/record-picker.vue";
import Statistics from "@c/user-main/breath/statistics.vue";
export default {
  components: {
    Parameter,
    RecordPicker,
    Statistics,
  },

  props: {
    patientDetailModel: Object,
  },

  methods: {
    onParaAdjustSucceed() {
      this.$refs.recordPicker.getModifyParaRecordList()
    }
  }
};
</script>

<style lang="scss" scoped>
.report {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-top {
      display: flex;
      justify-content: space-between;

      &-parameter {
        width: 1600px;
        overflow-x: scroll;
      }
    }

    &-line {
      background-color: #e8e8e8;
      height: 1px;
    }
  }
}
</style>