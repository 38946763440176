<template>
  <key-value-unit :title="title" :value="value"></key-value-unit>
</template>

<script>
import KeyValueUnit from "@c/common/key-value-unit/key-value-unit.vue";
export default {
  components: {
    KeyValueUnit,
  },

  props: {
    title: String,
    value: String,
  },
};
</script>

<style lang="scss" scoped>
/deep/.key-value-unit {
  &-key {
    font-weight: 400;
  }

  &-value {
    font-weight: 300;
  }
}
</style>