<template>
  <div class="print-header">
    <div class="print-header-content">
      <img class="print-header-content-logo" src="@imgs/report-logo.png" />
      <div class="print-header-content-info-left">
        <p>姓名：{{ info.patientName }}</p>
        <p>设备型号：{{ info.deviceModel }}</p>
      </div>
      <div class="print-header-content-info-left">
        <p>设备序列号：{{ info.deviceSN }}</p>
        <p>统计时间：{{ info.statisticsTime }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: Object,
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .print-header {
    display: block !important;
    position: running(print-header);
  }
}

.print-header {
  font-size: 24px;
  width: 100%;
  height: 100px;
  display: none;

  &-content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    &-logo {
      width: 140px;
      height: 45px;
    }

    &-info-left {
      color: #666666;
      font-size: 14px;
      line-height: 20px;
    }

    &-info-right {
      color: #666666;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
