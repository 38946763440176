<template>
  <div class="print-footer" :style="{ marginTop: `${marginTop}px` }">
    <div class="print-footer-content">
      <p class="print-footer-content-info">
        此报告仅为评估治疗效果时要考虑的数项要素之一，不能代替诊断数据。
      </p>
      <p class="print-footer-content-page">
        {{ pageInfo }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    firstPage: Number,
    totalPage: Number,
    marginTop: Number,
  },

  computed: {
    pageInfo() {
      return `Page  ${this.firstPage}/${this.totalPage}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .print-footer {
    display: block !important;
    position: running(print-footer);
  }
}

.print-footer {
  width: 100%;
  height: 50px;
  display: none;

  &-content {
    background-color: #f7f7f7;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-info {
      font-size: 14px;
    }

    &-page {
      font-size: 20px;
      font-weight: 600;
    }
  }
}
</style>